
import '@wangeditor/editor/dist/css/style.css' // 引入 css

import { onBeforeUnmount, ref, shallowRef, onMounted,defineComponent } from 'vue'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import { addLabelValue,  loadFile, tree, treeById } from "@/utils/tools";
import { client } from "@/client";
import { ElMessage } from "element-plus";

export default defineComponent({
  name: "page-edit",
  components: {
    Editor, Toolbar
  },
  setup() {
    // 编辑器实例，必须用 shallowRef
    const editorRef = shallowRef()

    // 内容 HTML
    const valueHtml = ref('<p>hello</p>')

    // 模拟 ajax 异步获取内容
    onMounted(() => {
        setTimeout(() => {
            valueHtml.value = '<p>模拟 Ajax 异步设置内容</p>'
        }, 1500)
    })
    const toolbarConfig = {}
    const editorConfig = {
      placeholder: '请输入内容...',
      MENU_CONF:{
        uploadImage:{
          // 自定义上传
          async customUpload(file: File, insertFn: (arg0: string, arg1: string, arg2: string) => void) {  // TS 语法
            let fileData = await loadFile(file);
            let ret = await client.callApi("pub/UploadImage",{file: fileData,filename: file.name});
            if (ret.isSucc) {
              let path = client.options.server + ret.res.path;
              insertFn(path,path, path)
            } else {
              ElMessage("上传文件必须小于1M");
            }
          // async customUpload(file, insertFn) {                   // JS 语法
              // file 即选中的文件
              // 自己实现上传，并得到图片 url alt href
              // 最后插入图片
          }
        }
      }
    }
    // 组件销毁时，也及时销毁编辑器
    onBeforeUnmount(() => {
        const editor = editorRef.value
        if (editor == null) return
        editor.destroy()
    })

    const handleCreated = (editor: any) => {
      editorRef.value = editor // 记录 editor 实例，重要！
    }

    return {
      editorRef,
      valueHtml,
      mode: 'default', // 或 'simple'
      toolbarConfig,
      editorConfig,
      handleCreated
    };
  },
  data: () => {
    return {
      categorySelected: [] as any[], //-1默认选中顶级栏目
      categoryProps: { checkStrictly: true },
      activeName: "first", //tab 默认显示第一个
      activeIndex: 0, //tab 内容默认显示第一个

      category: [] as any[], //当前所有栏目
      cateList: [] as any[], //所有栏目
      autoImg: true,
      autoDes: true,
      picNum: 1,

      params: {
        //接口入参
        id: 0,
        categoryId: 0,
        title: "",
        seo_title: "",
        seo_keywords: "",
        seo_description: "",
        source: "",
        author: "",
        createdAt: new Date(),
        updatedAt: new Date(),
        content: "禅悦",
        status: "1",
        pv: 0,
      },
      dialogVisible: false,
      disabled: false,
      paramsRules: {
        //校验规则
        title: [
          { required: true, message: "请输入栏目名称", trigger: "blur" },
          {
            min: 2,
            max: 50,
            message: "名称长度在 2 到 50 个字符之间",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {},
  async created() {
    let id = this.$route.params.id as string;
    this.params.id = parseInt(id);
    await this.detail();
    await this.query();
  },
  methods: {
    handleClick(tab: { index: number; }) {
      this.activeIndex = tab.index;
    },
    setContent(article: string) {
      this.params.content = article;
    },
    //查询栏目
    async query() {
      try {
        let res = await client.callApi("category/Find",{pageSize:1000});
        if (res.isSucc) {
          let data = res.res.list;
          let ids = treeById(this.params.categoryId, data);
          this.categorySelected = ids;
          let end = addLabelValue(tree(data));
          this.cateList = addLabelValue(data);
          this.category = [...end];
        }
      } catch (error) {
        console.log(error);
      }
    },

    //选择栏目
    handleChange(e: any[]) {
      if (e[0] != -1) {
        this.params.categoryId = e[e.length - 1];
      }
    },

    // 文章详情
    async detail() {
      try {
        let res = await client.callApi("page/Detail",{id:this.params.id});
        if (res.isSucc) {
          this.params = {
            ...res.res
          };
          this.params.status = this.params.status.toString();
        }
      } catch (error) {
        console.error(error);
      }
    },

    handleAttr(e: any) {
      console.log("e-->", e);
    },

    handleSubCid(e: any) {
      console.log("e-->", e);
    },

    //新增
    async update() {
      try {
        let res = await client.callApi("page/Detail",{
          ...this.params,

        });
        if (res.isSucc) {
          ElMessage({
            message: "更新成功^_^",
            type: "success",
          });
          this.$router.go(-1);
        }
      } catch (error) {
        console.log(error);
      }
    },

    submit(formName: string|number) {
      let refs:any = this.$refs[formName];
      refs.validate((valid: any) => {
        if (valid) {
          this.update();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
});
